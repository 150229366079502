
function getDefaultConnectionObj() {
    return {
        company: {},
        companyId: '',
        connectedCompany: {},
        connectedCompanyId: '',
        connections: [],
        storageLocations: {},
        isActive: 'true',
        notes: '',
        others: '',
        dateCreated: null,
        createdBy: '',
        dateUpdated: null,
        updatedBy: '',
    };
}

function cleanupFields(connection) {
    let cleanedObj = { ...connection };

    delete cleanedObj['companyName'];
    delete cleanedObj['connectedCompanyName'];
    delete cleanedObj['status'];
    delete cleanedObj['noOfStorageLocations'];
    delete cleanedObj['Storage Locations'];
    delete cleanedObj['Date Created'];
    delete cleanedObj['Date Updated'];
    delete cleanedObj['_showDetails'];

    return cleanedObj;
}

export const ConnectionUtil = {
    getDefaultConnectionObj,
    cleanupFields
}